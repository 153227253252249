import ReactDOM from 'react-dom'
//import { hydrate, render } from "react-dom";

import Root from './components/Root'
import './design-utils/variables.scss'

ReactDOM.render(<Root />, document.getElementById('root'))

// new content
//const rootElement = document.getElementById("root");
//if (rootElement.hasChildNodes()) {
//  hydrate(<Root />, rootElement);
//} else {
//  render(<Root />, rootElement);
//}
