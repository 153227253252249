import React from 'react'
import loadable from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'



const Container = loadable(() => import('./container'))
const Routes = loadable(() => import('./Routes'))
const ScrollToTop = loadable(() => import('../libs/ScrollToTop'))

const App = () => (
    <BrowserRouter>
      <ScrollToTop />
      <Container>
        <Routes />
      </Container>
    </BrowserRouter>
)

export default App